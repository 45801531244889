import { computed, reactive } from 'vue'
import { useMq } from 'vue3-mq'
import searchFactoryState from '@/composition/favorites/favoritesSearchFactory'
import gtmUtil from '@/utils/gtm-util'
import { getItemIds } from '@/composition/favorites/favoritesLSRepository'

const searchQueryState = reactive({
  take: 20,
  page: 1,
  terms: [],
  ids: null,
  searchType: null,
  sorting: null,
  language: null,
  contentLinkId: 0,
  updated: Date.now(),
  initialized: false
})

export default {
  searchQueryUse(routeData) {
    if (useMq().current !== 'mobile') {
      searchQueryState.take = 30
    }
    const state = searchQueryState

    const { search, searchMore, searchResult } = searchFactoryState.useSearchResult()

    // Added check for reloading results on different searchType
    if (routeData && routeData.searchType !== state.searchType) {
      state.initialized = false
    }

    if (!state.initialized && routeData) {
      state.ids = getItemIds()
      state.searchType = routeData.searchType
      state.terms = routeData.terms
      state.language = routeData.language
      state.page = routeData.page || 1
      state.sorting = routeData.sorting
      state.contentLinkId = routeData.contentLinkId
      state.initialized = true
      search(state)
    }

    const incrementPage = () => {
      state.page += 1
    }

    const resetPageNumber = () => {
      state.page = 1
    }

    const doSearch = () => {
      resetPageNumber()
      search(state)
    }

    const searchMoreResults = () => {
      incrementPage()
      searchMore(state)
    }

    const setTerms = (terms = []) => {
      state.terms = terms
      doSearch()
    }

    const addTerm = (term) => {
      if (state.terms.indexOf(term) !== -1) {
        return
      }

      state.terms.unshift(term)
      doSearch()
    }

    const removeTerm = (term) => {
      const index = state.terms.indexOf(term)
      if (index > -1) {
        const tempArray = state.terms
        tempArray.splice(index, 1)
        setTerms(tempArray)
      }
    }

    const setSorting = (sorting) => {
      if (state.sorting === sorting) {
        return
      }

      state.sorting = sorting
      doSearch()
      gtmUtil.pushSortingType(sorting)
    }

    const resetAllFilters = () => {
      state.terms = []
      doSearch()
    }

    return {
      searchQuery: state,
      searchResult,
      activeTerms: computed(() => state.terms),
      language: computed(() => state.language),
      searchType: computed(() => state.searchType),
      hasExtendedFilters: computed(() => state.searchType === 'Recipe'),
      setTerms,
      addTerm,
      removeTerm,
      setSorting,
      resetAllFilters,
      searchMoreResults
    }
  }
}
