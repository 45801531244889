<template>
  <div
    :id="model.urlSegment"
    :class="cssClass"
    class="c-step-by-step-block"
  >
    <Slider
      ref="slider"
      type="full"
      @ready="onReady"
      @slide="onSlideChange"
      @setOnPause="setOnPause"
    >
      <div
        v-if="firstItem"
        class="c-slide"
      >
        <StepByStepItem
          :model="firstItem"
          :progress="progress"
          heading-tag="h2"
        >
          <StepByStepControls
            :total-slides="totalSlides"
            :prev-disabled="prevDisabled"
            :next-disabled="nextDisabled"
            @onPrev="onPrev"
            @onNext="onNext"
          />
        </StepByStepItem>
      </div>
      <div
        v-for="(item, index) in sliderItems"
        :key="index"
        class="c-slide"
      >
        <StepByStepItem
          :model="item"
          :progress="progress"
        >
          <StepByStepControls
            :total-slides="totalSlides"
            :prev-disabled="prevDisabled"
            :next-disabled="nextDisabled"
            @onPrev="onPrev"
            @onNext="onNext"
          />
        </StepByStepItem>
      </div>
    </Slider>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import Slider from '@/components/Slider.vue'
import StepByStepItem from '@/components/blocks/StepByStepItem.vue'
import StepByStepControls from '@/components/blocks/StepByStepControls.vue'

export default {
  components: {
    Slider,
    StepByStepItem,
    StepByStepControls
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    sliderType: {
      type: String,
      default: 'quarter'
    }
  },
  setup(props) {
    const currentSlide = ref(null)
    const totalSlides = ref(0)
    const progress = computed(() => `${currentSlide.value}/${totalSlides.value}`)
    const prevDisabled = ref(true)
    const nextDisabled = ref(true)
    const slider = ref(null)

    const cssClass = computed(() => [
      (props.model.theme) ? `u-theme--${props.model.theme}` : '',
      { 'c-step-by-step__right-alignment': props.model.mediaContentAlignment === 'left' }
    ])

    const firstItem = computed(() => (props.model.items && props.model.items.length > 0 && props.model.items[0].isFirstItem
      ? props.model.items[0]
      : null
    ))

    const sliderItems = computed(() => (props.model.items && props.model.items.length > 1 && props.model.items[0].isFirstItem
      ? props.model.items.slice(1, props.model.items.length)
      : props.model.items
    ))

    const onPrev = () => {
      slider.value.prev()
    }

    const onNext = () => {
      slider.value.next()
    }

    const setOnPause = () => {
      slider.value.mySlider.querySelectorAll('video').forEach((vid) => vid.pause())
    }

    const updateNav = (info) => {
      currentSlide.value = info.displayIndex
      prevDisabled.value = !info.prevEnabled
      nextDisabled.value = !info.nextEnabled
    }

    const progressText = (item) => {
      if (item.subtitle) {
        return `${progress.value} - ${item.subtitle}`
      }

      return progress.value
    }

    const getPoster = (item) => (item.image && item.image.src)
        || item.video.poster

    return {
      slider,
      progress,
      prevDisabled,
      nextDisabled,
      firstItem,
      sliderItems,
      totalSlides,
      cssClass,

      onReady: (e) => {
        totalSlides.value = e.slideCount
        updateNav(e)
      },
      onPrev,
      onNext,
      progressText,
      onSlideChange: updateNav,
      setOnPause,
      getPoster
    }
  }
}
</script>
